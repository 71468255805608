.aboutContain {
  height: 100vh;
  padding-bottom: 7em !important;

  display: flex !important;
  flex-direction: row !important;
}

.card {
  background-color: black;
  margin: auto;
  width: 1000px;
  line-height: 3em;
  align-items: center !important;
}

.divider {
  border-bottom: 2px solid #ff3300;
  border-radius: 5px;
  width: 3em;

  background: black;
}

.card-header {
  font-size: 28px;

  margin: 2em 0 0.25em 0;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media (max-width: 992px) {
  .card {
    padding: 0;
   
  }

  .card-text {
    width: 100% !important;
    
 
  }
}
