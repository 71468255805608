strong {
  color: #ff3300;
  font-size: 24px;
}

.skillsContain {
  height: 100vh;

  display: flex !important;
  flex-direction: row !important;
}

#smart {
  filter: brightness(0.5);
}

.bingo {
  width: 300px;
}
.card-img-top {
  width: 275px !important;
}

.degree {
  margin-top: 1em;
}

.awards {
  font-size: 10px;
}

.card-img-top {
  width: 300px;
  height: 200px;
}

.description {
  font-size: 14px;
}
@media (max-width: 576px) {
}
