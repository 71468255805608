@import url(https://fonts.googleapis.com/css2?family=Fira+Mono&display=swap);
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css);
body {
  margin: 0;
  font-family: "Fira Mono", monospace;
  background-color: black;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff3300;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.aboutContain {
  height: 100vh;
  padding-bottom: 7em !important;

  display: -webkit-flex !important;

  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

.card {
  background-color: black;
  margin: auto;
  width: 1000px;
  line-height: 3em;
  -webkit-align-items: center !important;
          align-items: center !important;
}

.divider {
  border-bottom: 2px solid #ff3300;
  border-radius: 5px;
  width: 3em;

  background: black;
}

.card-header {
  font-size: 28px;

  margin: 2em 0 0.25em 0;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media (max-width: 992px) {
  .card {
    padding: 0;
   
  }

  .card-text {
    width: 100% !important;
    
 
  }
}

strong {
  color: #ff3300;
  font-size: 24px;
}

.educationContain {
  height: 100vh;

  display: -webkit-flex !important;

  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

.degree {
  margin-top: 1em;
}

.awards {
  font-size: 10px;
}

.educationCard {
  padding: 0;
  width: 75%;
}

@media (max-width: 576px) {
  .col-5 {
    width: 100%;
  }

  .educationCard {
    width: 99%;
  }
}

.navbar {
  background: transparent;
}

.container-fluid {
  padding: 1em;
}
.active {
  background-color: transparent !important;
}

/* .nav-link:hover {
  border-bottom: 2px solid lightblue !important;
} */

.show {
  border-top: 1px solid rgb(255, 51, 0);
  border-bottom: 1px solid rgb(255, 51, 0);

  background-color: rgb(0, 0, 0, 0.9);
}

.collapsing {
  border-top: 1px solid rgb(255, 51, 0);
  border-bottom: 1px solid rgb(255, 51, 0);

  background-color: rgb(0, 0, 0, 0.9);
}

.nav-link {
  border-radius: 0 !important;
}

.nav-item {
  margin: 0;
  font-size: 12px;
}

.navbar-toggler {
  color: white !important;
}
.bi-list {
  font-size: 32px !important;
}
.bi-x {
  color: #ff3300;
  width: 100px;
  font-size: 32px;
}

.nav-pills {
  -webkit-justify-content: center;
          justify-content: center;
}

.nav {
  margin: 0;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media (max-width: 576px) {
  .nav-item {
    font-size: 24px;
    width: 100%;
    text-align: start;
  }

 
}

.welcomeContain {
  background: url(/static/media/123.ab93c0eb.jpg) no-repeat center center/cover;
  height: 100vh;

  display: -webkit-flex !important;

  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

.info {
  padding-top: 70vh;
  padding-left: 3vw;
  width: 60vw;
}

.lightBlue {
  color: #ff3300 !important;
}

.bi {
  position: relative;
  bottom: 0;
  color: #ff3300 !important;
}

.inlineBlock {
  display: inline-block !important;
}

.col-auto {
  padding-left: 0;
}

a:hover {
  color: #ff3300 !important;
}

a {
  text-decoration: none;
  color: white;
}
p {
  margin: 0;
}
@media (max-width: 576px) {
  .info {
    padding-top: 60vh;
    padding-left: 3vw;
    width: 85vw;
  }
}

@include media-breakpoint-down(sm) {
  .carolSm {
    width: 75%;
  }
}

strong {
  color: #ff3300;
  font-size: 24px;
}

.skills {
}

.skillsContain {
  height: 100vh;

  display: -webkit-flex !important;

  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

.degree {
  margin-top: 1em;
}

.awards {
  font-size: 10px;
}

.educationCard {
  padding: 0;
  width: 75%;
}

@media (max-width: 576px) {
  .col-5 {
    width: 100%;
  }

  .educationCard {
    width: 99%;
  }
}

strong {
  color: #ff3300;
  font-size: 24px;
}

.skillsContain {
  height: 100vh;

  display: -webkit-flex !important;

  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

#smart {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.bingo {
  width: 300px;
}
.card-img-top {
  width: 275px !important;
}

.degree {
  margin-top: 1em;
}

.awards {
  font-size: 10px;
}

.card-img-top {
  width: 300px;
  height: 200px;
}

.description {
  font-size: 14px;
}
@media (max-width: 576px) {
}

.contactContain {
  height: 100vh;

  color: white;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

/* .myForm {
  margin-top: 25%;
} */

#contactbtn {
  background-color: #ff3300 !important;
  border: #ff3300 !important;
  width: 200px;
}

@media (max-width: 576px) {
  .contactContain {
    margin-top: 100vh;
  }

  .please {
    margin-top: 100% !important;
  }
}

.footer {
  color: white;
  font-size: 12px;
}

@media (max-width: 576px) {
  .footer {
    margin-bottom: 7em;
  }
}

