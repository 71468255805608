.navbar {
  background: transparent;
}

.container-fluid {
  padding: 1em;
}
.active {
  background-color: transparent !important;
}

/* .nav-link:hover {
  border-bottom: 2px solid lightblue !important;
} */

.show {
  border-top: 1px solid rgb(255, 51, 0);
  border-bottom: 1px solid rgb(255, 51, 0);

  background-color: rgb(0, 0, 0, 0.9);
}

.collapsing {
  border-top: 1px solid rgb(255, 51, 0);
  border-bottom: 1px solid rgb(255, 51, 0);

  background-color: rgb(0, 0, 0, 0.9);
}

.nav-link {
  border-radius: 0 !important;
}

.nav-item {
  margin: 0;
  font-size: 12px;
}

.navbar-toggler {
  color: white !important;
}
.bi-list {
  font-size: 32px !important;
}
.bi-x {
  color: #ff3300;
  width: 100px;
  font-size: 32px;
}

.nav-pills {
  justify-content: center;
}

.nav {
  margin: 0;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media (max-width: 576px) {
  .nav-item {
    font-size: 24px;
    width: 100%;
    text-align: start;
  }

 
}
