@include media-breakpoint-down(sm) {
  .carolSm {
    width: 75%;
  }
}

strong {
  color: #ff3300;
  font-size: 24px;
}

.skills {
}

.skillsContain {
  height: 100vh;

  display: flex !important;
  flex-direction: row !important;
}

.degree {
  margin-top: 1em;
}

.awards {
  font-size: 10px;
}

.educationCard {
  padding: 0;
  width: 75%;
}

@media (max-width: 576px) {
  .col-5 {
    width: 100%;
  }

  .educationCard {
    width: 99%;
  }
}
