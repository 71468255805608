@import url("https://fonts.googleapis.com/css2?family=Fira+Mono&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

body {
  margin: 0;
  font-family: "Fira Mono", monospace;
  background-color: black;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff3300;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
